// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyD276BVVDdbQak9V_6onYWJuXVpdaB_k7U",
  authDomain: "gasbill6-ee0ac.firebaseapp.com",
  databaseURL: "https://gasbill6-ee0ac-default-rtdb.firebaseio.com",
  projectId: "gasbill6-ee0ac",
  storageBucket: "gasbill6-ee0ac.appspot.com",
  messagingSenderId: "523281113986",
  appId: "1:523281113986:web:84b34626027a99bf9ce59b",
  measurementId: "G-YF6DT5S5FV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };